<template>
    <v-row justify="center" class="py-5" style="height: 100%; min-width: 240px;" align="center">
        <v-col cols="10" sm="8" md="6" lg="4">
            <v-card elevation="2" class="my-6">
                <v-app-bar color="orange darken-2" dark flat dense>
                    <v-app-bar-title>Registration is currently closed</v-app-bar-title>
                </v-app-bar>
                <v-card-text class="pt-8">
                    <p>We are sorry for this inconvenience.</p>
                    <!-- TODO: if we know that we saved their info (from the signup page for example) we might want to show a message like: "Your information has been saved and we will contact you when registration is open"; the right way to do it would be for the previous page to include the email address in the query; then from this page we send a request to the server to check ifthat email is in the queue; the server will only respond with an answer if the same email has been verified in this session, otherwise it will respond with 401; so if we get the unauthorized error, we don't know if it was saved and we won't show the message, but if we get a 200 with a status, and the status is that it was saved (and maybe even the info that was saved like email and name and account type) we could show here what was saved to give the user assurance tha we have it -->
                    <!-- TODO: if organization has support website URL, show that link -->
                    <!-- TODO: if organization has main website URL, show that link -->
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<style>
/* regular input height is 56px; dense input height is 40px */
/* font awesome icon width is 16px, while append/prepend-inner width is 20px */
.v-input .v-input__prepend-inner {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
.v-input .v-input__prepend-outer {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    components: {
    },
    data: () => ({
    }),
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isAuthenticatedReady,
            session: (state) => state.session,
            user: (state) => state.user,
            account: (state) => state.account,
            accountList: (state) => state.accountList,
            brandselector: (state) => state.brandselector,
            brandprofile: (state) => state.brandprofile,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            mainWebsiteURL: 'mainWebsiteURL',
            primaryColor: 'primaryColor',
            primaryTextColor: 'primaryTextColor',
            primaryButtonStyle: 'primaryButtonStyle',
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
    },
    watch: {
        focus() {
            this.init();
        },
    },
    methods: {
        async init() {
            console.log('signup.vue: init');
            // TODO: load organization registration mode (and we'll reload it on focus) so if user returns to this page and it changed, we can redirect user to signup form or somewhere else according to organization's settings
        },
    },
    mounted() {
        this.init();
    },
};
</script>
